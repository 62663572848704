import styled from "styled-components";
import React, { useEffect, useCallback } from "react";
import { BottomNavSegment } from "../components/PageSegments/BottomNav";
import { BasicLayout } from "../layouts/BasicLayout";
import SpecialOfferModal from "../components/Modal/SpecialOfferModal";
import {
  SpecialOfferBar,
  HeroSegment,
  AppPitchSegment,
  TestimonySegment,
} from "../components/PageSegments/special-offer/landing";
import { useOnboardingContext } from "../components/PageSegments/onboarding/OnboardingContext";
import { web_acq_landing_page_view } from "../services/mixpanel/acquisition-events";
import { fbq } from "../services/pixel";
import { setMixpanelProperties } from "../services/mixpanel/mixpanel";
// import { REMOTE_CONFIG_STRING_KEYS } from "../services/firebase-remote-config";
// import useRemoteConfig from "../hooks/useRemoteConfig";
// import { VariantComponent } from "../components/PageSegments/special-offer/variantComponent";
// import { LP_VARIANT_MAPPING } from "../util/variantMappings";

// const TEST_KEY = REMOTE_CONFIG_STRING_KEYS.landingPageVariation;

export default (data: any) => {
  const { setOnboardingLandingPage } = useOnboardingContext();
  const urlParams = new URLSearchParams(data.location.search);
  const fromParam = urlParams.get("from") ?? undefined;
  // const useVariantParam = urlParams.get("useVariant") ?? undefined;
  // const [variant, useDefault] = useRemoteConfig(TEST_KEY);
  // const validVariants = ["control", "1", "2", "3", "4", "default"];

  // const useVariant = useCallback(() => {
  //   if (useVariantParam) {
  //     return useVariantParam;
  //   }

  //   return variant;
  // }, [useVariantParam, variant]);


  useEffect(() => {
    setMixpanelProperties(
      { "202410_WebProductPositioningLP": "default" },
      true,
    );
    // if (validVariants.includes(variant as string)) {
    //   setMixpanelProperties(
    //     {
    //       "202410_WebProductPositioningLP":
    //         useVariant() === "default" || useVariant() === "control"
    //           ? useVariant()
    //           : LP_VARIANT_MAPPING[useVariant() as keyof typeof LP_VARIANT_MAPPING].experiment,
    //     },
    //     true,
    //   );
    // }
    // }, [useVariant, validVariants, variant]);
  }, []);

  useEffect(() => {
    web_acq_landing_page_view();
    fbq("trackCustom", "landing_page_view");
    setOnboardingLandingPage("web_discount_flow");
  }, [setOnboardingLandingPage, fromParam]);

  // if (!validVariants.includes(variant as string) && !useDefault) {
  //   return null;
  // }

  return (
    <BasicLayout>
      <Main>
        <SpecialOfferBar />
        <HeroSegment />
        <TestimonySegment />
        <AppPitchSegment />
        <SpecialOfferModal />
        {/* {
          // eslint-disable-next-line no-negated-condition
          useVariant() === "default" || useVariant() === "control" ? (
            <>
              <SpecialOfferBar />
              <HeroSegment />
              <TestimonySegment />
              <AppPitchSegment />
              <SpecialOfferModal />
            </>
          ) : (
            <VariantComponent variant={useVariant()} />
          )
        } */}
      </Main>
      <BottomNavSegment />
    </BasicLayout>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
